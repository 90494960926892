* {
  margin: 0;
  padding: 0;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #222;
  font: 400 13pt / 1.6 Montserrat, sans-serif;
}

@media (max-width: 1024px) {
  body {
    font-size: 11pt;
  }
}

a {
  color: #1c71b4;
  text-decoration: none;
  transition: all .2s ease-out;
  display: inline-block;
}

a:hover {
  color: #0d4470;
}

main {
  max-width: 1600px;
  margin: 0 auto;
  padding: 100px 120px;
}

@media (max-width: 1250px) {
  main {
    padding: 50px 70px;
  }
}

@media (max-width: 1024px) {
  main {
    padding: 15px;
  }
}

section {
  margin-bottom: 100px;
}

@media (max-width: 1024px) {
  section {
    margin-bottom: 50px;
  }
}

p {
  margin-bottom: 20px;
}

h2 {
  text-align: center;
  margin-bottom: 50px;
  font-size: 40pt;
}

@media (max-width: 1024px) {
  h2 {
    font-size: 30pt;
  }
}

.gPMrEW_header {
  grid-template-rows: min-content min-content 1fr;
  grid-template-columns: min-content 1fr;
  column-gap: 50px;
  display: grid;
}

@media (max-width: 1024px) {
  .gPMrEW_header {
    column-gap: 20px;
  }
}

.gPMrEW_header .gPMrEW_photo {
  grid-area: 1 / 1 / span 2 / span 1;
}

.gPMrEW_header .gPMrEW_photo img {
  border-radius: 5%;
  width: 300px;
  height: 300px;
  margin-bottom: 30px;
  display: block;
  overflow: hidden;
}

@media (max-width: 1024px) {
  .gPMrEW_header .gPMrEW_photo img {
    width: 25vw;
    height: 25vw;
  }
}

.gPMrEW_header .gPMrEW_name {
  grid-area: 1 / 2 / span 1 / span 1;
}

.gPMrEW_header .gPMrEW_name h1 {
  margin-bottom: 15px;
  font-size: 55pt;
  line-height: .8;
}

@media (max-width: 1024px) {
  .gPMrEW_header .gPMrEW_name h1 {
    font-size: 26pt;
    line-height: .9;
  }
}

.gPMrEW_header .gPMrEW_name .gPMrEW_subtitle {
  margin-bottom: 40px;
  font-size: 25pt;
  line-height: 1.3;
}

.gPMrEW_header .gPMrEW_name .gPMrEW_subtitle span {
  white-space: nowrap;
}

@media (max-width: 1024px) {
  .gPMrEW_header .gPMrEW_name .gPMrEW_subtitle {
    margin-bottom: 20px;
    font-size: 16pt;
    line-height: 1.4;
  }
}

.gPMrEW_header .gPMrEW_contact {
  color: #666;
  grid-area: 3 / 1 / span 1 / span 1;
  padding-left: 30px;
  font-size: 15pt;
}

.gPMrEW_header .gPMrEW_contact p {
  margin-bottom: 15px;
}

@media (max-width: 1024px) {
  .gPMrEW_header .gPMrEW_contact p {
    margin-bottom: 5px;
  }
}

.gPMrEW_header .gPMrEW_contact svg {
  margin-right: 10px;
  width: 20px !important;
}

@media (max-width: 1024px) {
  .gPMrEW_header .gPMrEW_contact {
    grid-area: 2 / 2 / span 1 / span 1;
    margin-bottom: 30px;
    padding-left: 0;
    font-size: 12pt;
  }
}

.gPMrEW_header .gPMrEW_intro {
  grid-area: 2 / 2 / span 2 / span 1;
}

@media (max-width: 1024px) {
  .gPMrEW_header .gPMrEW_intro {
    grid-area: 3 / 1 / span 1 / span 2;
  }
}

.ytStyW_experience {
  max-width: 800px;
  margin: 0 auto 50px;
}

.ytStyW_title {
  margin-bottom: 15px;
  font-size: 22pt;
  line-height: 1.2;
}

.ytStyW_company {
  margin-bottom: 0;
  font-size: 18pt;
  font-weight: 400;
  line-height: 1.2;
}

.ytStyW_website {
  color: #1c71b4;
  font-size: 11pt;
  text-decoration: none;
  display: block;
}

.ytStyW_dates {
  float: right;
}

.ytStyW_content {
  padding-top: 30px;
}

ul {
  padding-left: 30px;
}

li {
  margin-bottom: 10px;
}

/*# sourceMappingURL=index.0e403740.css.map */
